import React, { useState } from "react";
import DataTable from "../../../../Components/Others/DataTable";
import {
  CancelButton,
  CreateButton,
  ExportButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link, useLocation } from "react-router-dom";
import SearchBar from "../../../../Components/Utils/SearchBar";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import { useEffect } from "react";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { NuDataTime, NuDate } from "../../../../Components/Utils/Input";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditCQ from "./MultiEditCQ";
import { GetAccess, GetRole } from "../../../../Components/Utils/roles";
import useFetchMDIndex from "../../../../Components/Hooks/useFetchMDIndex";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import CloseIcon from "../../../../Components/Icons/Close";
import axiosInstance from "../../../../Services/axiosCommon";
import DataFormat from "../../../../Components/Utils/NuDate"

function CustomerQuotationIndex() {
  const location = useLocation();
  const state = location.state;

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(state?.approvalStatus ? true : false);

  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showExport, setShowExport] = useState(false);

  const { value: Availablity } = useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "Availablity_CustomerQuotes"
  );
  // const { value: DeliveryTerm } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "DeliveryTerm_CustomerQuotes"
  // );
  // const { value: PaymentTerms } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "PaymentTerms_CustomerQuotes"
  // );
  const { value: DeliveryTerm, getData: refDeliveryTerms } = useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "DeliveryTerms_Supplierqoutes",
    showFilterDialog||showExport
  );
  const { value: PaymentTerms, getData: refPaymentTerms } = useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "PaymentTerms_Supplierqoutes",
    showFilterDialog||showExport
  );
  const { value: PaymentMethod } = useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "PaymentMethod_CustomerQuotes",
    showFilterDialog||showExport
  );
  // const { value: Validity } = useFetchMDIndex(
  //   `/dropdown/dropdown-details/`,
  //   "Validity_CustomerQuotes"
  // ,showFilterDialog);
  const { value: Validity, getData: refValidity } = useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "Validity_Supplierquotes",
    showFilterDialog||showExport
  );
  const { value: status } = useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "Status_customerQuotes",
    showFilterDialog||showExport
  );
  const { value: leadTime } = useFetchMDIndex(
    `/dropdown/dropdown-details/`,
    "leadTime_customerQuotes",
    showFilterDialog||showExport
  );
  const { data: Salesman } = UseDropDown("/user/salesman/drop-down");

  const [filterProps, setFilterProps] = useState({
    status: "",
    paymentMethod: "",
    leadTime: "",
    validity: "",
    availability: "",
    approvalStatus: "",
    paymentTerms: "",
    deliveryTerms: "",
    fromDate: "",
    toDate: "",
    salesPerson: "",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
    status:  "",
    paymentMethod: "",
    leadTime: "",
    validity: "",
    availability: "",
    approvalStatus:state?.approvalStatus ? state.approvalStatus : "",
    paymentTerms: "",
    deliveryTerms: "",
    fromDate: "",
    toDate: "",
    salesPerson: "",
  });
  const clearFilter = () => {
    setParams({
      ...params,
      status: "",
      paymentMethod: "",
      leadTime: "",
      validity: "",
      availability: "",
      approvalStatus: "",
      paymentTerms: "",
      deliveryTerms: "",
      fromDate: "",
      toDate: "",
      salesPerson: "",
    });
    formik.values.status = "";
    formik.values.paymentMethod = "";
    formik.values.leadTime = "";
    formik.values.validity = "";
    formik.values.availability = "";
    formik.values.approvalStatus = "";

    formik.values.deliveryTerms = "";
    formik.values.fromDate = "";
    formik.values.toDate = "";
    formik.values.paymentTerms = "";
    formik.values.salesPerson = "";

    setFilter(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff", value);
      setParams({
        ...params,
        paymentTerms: value.paymentTerms,
        toDate: value.toDate,
        salesPerson: value.salesPerson,

        fromDate: value.fromDate,
        deliveryTerms: value.deliveryTerms,
        availability: value.availability,
        approvalStatus: value.approvalStatus,

        validity: value.validity,
        leadTime: value.leadTime,
        paymentMethod: value.paymentMethod,
        status: value.status,
      });
      closeFilterDialog();
      setFilter(true);
      setFilterProps({
        status: "",
        paymentMethod: "",
        leadTime: "",
        validity: "",
        availability: "",
        approvalStatus: "",
        paymentTerms: "",
        deliveryTerms: "",
        fromDate: "",
        toDate: "",
        salesPerson: "",
      });
    },
  });

  const { data, totalCount, getData, dataLoading } = UseGetDataSearch(
    "/customer-quotations/",
    params
  );

  const defaultHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "YPR",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Quote No.",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Date",
      width: "10%",
      field: "date",
      type: "date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Amount",
      width: "10%",
      type: "amount",
      field: "totalPrice",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Customer",
      width: "10%",
      field: "customerName",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Days Taken",
    //   width: "10%",
    //   field: "totalPrice",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher:getData,
        id: "id",
        removeValue: "ref",
        url: `/customer-quotations/`,
        moduleName: "Customer Quotations",
        from: `/sales/quotes`,
        editLink: "/sales/quotes/edit/{id}/viewqoute",
        viewLink: "/sales/quotes/view/",
        // EmailRequired: true,
        // emailType: true
      },
    },
  ];
  const availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "YPR",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Quote No.",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Date",
      width: "10%",
      field: "date",
      type: "date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Amount",
      width: "10%",
      type: "amount",
      field: "totalPrice",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Customer",
      width: "10%",
      field: "customerName",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Days Taken",
    //   width: "10%",
    //   field: "totalPrice",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Payment Terms",
      width: "10%",
      field: "paymentTerms",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Delivery Terms",
      width: "10%",
      field: "deliveryTerms",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Validity",
      width: "10%",
      field: "validity",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Approved By ",
      width: "10%",
      field: "approvedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Prepared By ",
      width: "10%",
      field: "preparedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Reviewed By ",
      width: "10%",
      field: "reviewedByName",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher:getData,
        id: "id",
        removeValue: "ref",
        url: `/customer-quotations/`,
        moduleName: "Customer Quotations",
        from: `/sales/quotes`,
        editLink: "/sales/quotes/edit/{id}/viewqoute",
        viewLink: "/sales/quotes/view/",
        // EmailRequired: true,
        // emailType: true
      },
    },
  ];

  useEffect(() => {
    setParams({ ...params, page: page, per_page: pageCount });
  }, [page, pageCount]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  const closeFilterDialogebox = () => {
    setShowExport(false);
  };

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [listofNumbers, setListofNumbers] = useState([]);
  const [fullList, setFullList] = useState([]);

  const selectOneFunction = (value) => {
    let NewId;
    if (listofNumbers && listofNumbers.some((Item) => Item === value)) {
      NewId = listofNumbers.filter((Temp) => Temp !== value);
      setListofNumbers(NewId);
    } else {
      setListofNumbers([...listofNumbers, value]);
    }
  };

  const selectMultipleFunction = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setListofNumbers(fullList);
    } else {
      setListofNumbers([]);
    }
  };

  useEffect(() => {
    let tempArr = [];
    data &&
      data.forEach((i) => {
        console.log("I", i?.id);
        tempArr.push(i?.id);
      });
    setFullList(tempArr);
  }, [data]);

  const [exportValue, setExportValue] = useState({
    type: "all",
    approvalStatus: "",
    salesPerson: "",
    status: "",
    leadTime: "",
    validity: "",
    availability: "",
    paymentMethod: "",
    paymentTerms: "",
    deliverTerms: "",
    fromDate: "",
    toDate: "",
  });
  const ExportFormik = useFormik({
    enableReinitialize: true,
    initialValues: exportValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (value, { resetForm }) => {

      try {
        const response = await axiosInstance.get(`/customer-quotations/export-to-excel?approvalStatus=${value.approvalStatus}&salesPerson=${value.salesPerson}&status=${value.status}&leadTime=${value.leadTime}&validity=${value.validity}&availability=${value.availability}&paymentMethod=${value.paymentMethod}&paymentTerms=${value.paymentTerms}&deliverTerms=${value.deliverTerms}&fromDate=${value.fromDate}&toDate=${value.toDate}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element
       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `CQ Report ${DataFormat({value:new Date(), format:"DD-MMM-YYYY"})}.xlsx`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
    },
  });

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[{ name: "Sales" }, { name: "Customer Quotation" }]}
        />
        <div className=" flex gap-2">
          <SearchBar
            handleChange={(e) => {
              setParams({ ...params, search: e.target.value, page: 1 });
              setPage(1);
            }}
          />
          {GetRole() && (
            <ExportButton handleClick={() => setShowExport(true)} />
          )}

          <FilterButton handleClick={() => setShowFilterDialog(true)} />
          {filter && (
            <FilterButton handleClick={() => clearFilter()} label="Clear" />
          )}

          {/* <CreateButton label="Import" /> */}
          {GetAccess("customer_quote_create") && (
            <Link to="/sales/quotes/create/viewqoutes">
              <CreateButton label="Quote" />
            </Link>
          )}
        </div>
      </div>
      <CustomDataTable
        fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeaders}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Customer Quotes List"
        storageName="CustomerQuoteTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="id"
        editAccess="customer_quote_edit"
        deteleAccess="customer_quote_delete"
        dataLoading={dataLoading}
      />
      {showFilterDialog && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-xl mb-4">Filter Customer-Quotation</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col mb-4">
                <p className="py-2 px-6 text-m">Date</p>
                <div className="flex justify-between  w-full ">
                  <NuDate
                    label="From "
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="fromDate"
                    width="w-full "
                  />
                  <NuDate
                    label="To "
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="toDate"
                    width="w-full "
                  />
                </div>
                <FormikSelectTwo
                  type="text"
                  label="Deliver Terms"
                  placeholder="Choose"
                  Options={DeliveryTerm}
                  formik={formik}
                  name="deliveryTerms"
                  width="w-full "
                />
                <FormikSelectTwo
                  type="text"
                  label="Payment Terms"
                  placeholder="Choose"
                  Options={PaymentTerms}
                  formik={formik}
                  name="paymentTerms"
                  width="w-full "
                />

                {/* <FormikSelectTwo
                  label="Payment Method"
                  placeholder="Choose"
                  Options={PaymentMethod}
                  formik={formik}
                  name="paymentMethod"
                  width="w-full "
                /> */}
                <FormikSelectTwo
                  type="text"
                  label="Product Availability"
                  placeholder="Choose"
                  Options={Availablity}
                  formik={formik}
                  name="availability"
                  width="w-full "
                />
                <FormikSelectTwo
                  type="text"
                  label="Quotation Validity"
                  placeholder="Choose"
                  Options={Validity}
                  formik={formik}
                  name="validity"
                  width="w-full "
                />
                {/* <FormikSelectTwo
                  type="text"
                  label="Lead Time"
                  placeholder="Choose"
                  Options={leadTime}
                  formik={formik}
                  name="leadTime"
                  width="w-full "
                /> */}
                <FormikSelectTwo
                  type="text"
                  label="Sales Person"
                  placeholder="Choose"
                  Options={Salesman}
                  formik={formik}
                  name="salesPerson"
                  width="w-full"
                />
                <FormikSelectTwo
                  type="text"
                  label="Status"
                  placeholder="Choose"
                  Options={status}
                  formik={formik}
                  name="status"
                  width="w-full"
                />
                <FormikSelectTwo
                  type="text"
                  label="Approval Status"
                  placeholder="Approval Status"
                  Options={[
                    {
                      value: "Pending",
                      label: "Pending",
                    },
                    {
                      value: "Verified",
                      label: "Verified",
                    },
                    {
                      value: "Approved",
                      label: "Approved",
                    },
                  ]}
                  formik={formik}
                  name="approvalStatus"
                  width="w-full "
                />
                {/* <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          /> */}
              </div>
              <div className="flex justify-end gap-2">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" />
              </div>
            </form>
          </div>
        </div>
      )}
      {showExport && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[50%] 2xl:min-w-[40%] max-w-[50%] 2xl:max-w-[40%] max-h-[80vh] relative bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <div className=" w-full flex justify-between items-center  bg-white">
              <h2 className=" text-sm 2xl:text-base font-medium ">
                Export Data
              </h2>
              <div
                className=" cursor-pointer "
                onClick={() => setShowExport(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <form onSubmit={ExportFormik.handleSubmit}>
              <div className=" w-full flex flex-wrap">
                <div className=" w-full flex ">
                  <div className=" w-1/2 flex items-center px-6 py-3 ">
                    <input
                      id="default-radio-1"
                      type="radio"
                      value="all"
                      name="type"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={ExportFormik.handleChange}
                      checked={ExportFormik.values.type =="all"}
                    />
                    <label
                      htmlFor="default-radio-1"
                      className="ms-2 text-xs 2xl:text-sm  text-gray-900 "
                    >
                      All Data
                    </label>
                  </div>

                  <div className="w-1/2 flex items-center px-6 py-3">
                    <input
                      id="default-radio-2"
                      type="radio"
                      value="filter"
                      name="type"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={ExportFormik.handleChange}
                      checked={ExportFormik.values.type == "filter"}
                    />
                    <label
                      htmlFor="default-radio-2"
                      className="ms-2 text-xs 2xl:text-sm  text-gray-900"
                    >
                      Filter Data
                    </label>
                  </div>
                </div>
                {ExportFormik.values.type === "filter" && (
                  <>
                    <NuDate
                      label="From "
                      placeholder="dd/mm/yyyy"
                      formik={ExportFormik}
                      name="fromDate"
                      width="w-1/2 "
                    />
                    <NuDate
                      label="To "
                      placeholder="dd/mm/yyyy"
                      formik={ExportFormik}
                      name="toDate"
                      width="w-1/2"
                    />

                    <FormikSelectTwo
                      type="text"
                      label="Sales Person"
                      placeholder="Choose"
                      Options={Salesman}
                      formik={ExportFormik}
                      name="salesPerson"
                      width="w-1/2"
                    />

                    <FormikSelectTwo
                      type="text"
                      label="Approval Status"
                      placeholder="Approval Status"
                      Options={[
                        {
                          value: "Pending",
                          label: "Pending",
                        },
                        {
                          value: "Verified",
                          label: "Verified",
                        },
                        {
                          value: "Approved",
                          label: "Approved",
                        },
                      ]}
                      formik={ExportFormik}
                      name="approvalStatus"
                      width="w-1/2 "
                    />

                    <FormikSelectTwo
                      type="text"
                      label="Status"
                      placeholder="Choose"
                      Options={status}
                      formik={ExportFormik}
                      name="status"
                      width="w-1/2"
                    />

                    <FormikSelectTwo
                      type="text"
                      label="Deliver Terms"
                      placeholder="Choose"
                      Options={DeliveryTerm}
                      formik={ExportFormik}
                      name="deliveryTerms"
                      width="w-1/2 "
                    />
                    <FormikSelectTwo
                      type="text"
                      label="Payment Terms"
                      placeholder="Choose"
                      Options={PaymentTerms}
                      formik={ExportFormik}
                      name="paymentTerms"
                      width="w-1/2 "
                    />

                    <FormikSelectTwo
                      label="Payment Method"
                      placeholder="Choose"
                      Options={PaymentMethod}
                      formik={ExportFormik}
                      name="paymentMethod"
                      width="w-1/2 "
                    />
                    <FormikSelectTwo
                      type="text"
                      label="Product Availability"
                      placeholder="Choose"
                      Options={Availablity}
                      formik={ExportFormik}
                      name="availability"
                      width="w-1/2 "
                    />
                    <FormikSelectTwo
                      type="text"
                      label="Quotation Validity"
                      placeholder="Choose"
                      Options={Validity}
                      formik={ExportFormik}
                      name="validity"
                      width="w-1/2 "
                    />
                    {/* <FormikSelectTwo
                      type="text"
                      label="Lead Time"
                      placeholder="Choose"
                      Options={leadTime}
                      formik={ExportFormik}
                      name="leadTime"
                      width="w-1/2"
                    /> */}
                  </>
                )}
                <div className="flex w-full justify-end gap-2">
                  <CancelButton handleClick={closeFilterDialogebox} />
                  <SubmitButton name="Export" />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {showEdit && (
        <MultiEditCQ
          data={listofNumbers}
          getData={getData}
          onClose={() => setShowEdit(false)}
        />
      )}
      <ModalContainer
        visiable={showDelete}
        title="Warning"
        content={`Are you sure, All selected datas will be removed from YPR List!`}
        handleClick={() => {}}
        closeModal={() => setShowDelete(false)}
      />
    </div>
  );
}

export default CustomerQuotationIndex;
