import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import {
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  NuDate,
  NuInput,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo, {
  SelectProductWithError,
} from "../../../../Components/Utils/Select";
import Attachement, {
  videotype,
} from "../../../../Components/Utils/CustomAttrachmentupload";
import { useToast } from "../../../../Components/Others/ToastServices";
import DeleteIcon from "../../../../Components/Icons/Delete";
import Select from "react-select";
import { TextMediumBase } from "../../../../Components/Text/MediumText";
import NuAmount from "../../../../Components/Utils/NuAmount";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import CloseIcon from "../../../../Components/Icons/Close";
import Doc from "../../../../Components/Icons/Doc";
import Xls from "../../../../Components/Icons/Xls";
import Txt from "../../../../Components/Icons/Txt";
import Pdf from "../../../../Components/Icons/Pdf";
import Video from "../../../../Components/Icons/Video";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
import { useCurrency } from "../../../../Context/CurrencyContext";
import UseProductDropDownNew from "../../../../Components/Hooks/useProductSearch";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function RFQEditSQ() {
  const { sqEditId } = useParams();
  const { currencySymbol } = useCurrency();
  const toast = useToast();
  const { value: Validity } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Validity_Supplierquotes"
  );
  const { value: PaymentTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentTerms_Supplierqoutes"
  );
  const { value: LeadTime } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "LeadTime_Supplierqoutes"
  );
  const { value: DeliveryTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryTerms_Supplierqoutes"
  );
  const { value: paymentMethod } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "paymentMethod_supplierquotes"
  );
  const { value: Unit } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Unit_item"
  );
  const { value: Status, getData: refStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_Supplierqoutes"
  );
  // let { data: Item, setData: NewSetData } = UseDropDownNew("/items/new-drop-down");
  const [search, setSearch] = useState("");
  const { data: Item } = UseProductDropDownNew("/items/new-drop-down", search);
  let { data: currency } = UseDropDown("/currency/drop-down");
  const { Oncancel, isLoading } =
    useEditFormData();

  const [image, setImage] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileRemoveList, setFileRemoveList] = useState([]);
  const [filePathList, setFilePathList] = useState([]);
  const [missingProducts, setMissingProducts] = useState([]);
  const [prlist, setPrlist] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const [data, setData] = useState({});
  const [remove, setRemove] = useState([]);
  let { data: suppliers } = UseDropDown("/vendors/drop-down");
  // const [isDisabledd, setIsDisabledd] = useState(true);

  const location = useLocation();
  const from = location.state.from;

  const [createNew, setCreateNew] = useState({
    approvalStatus: "",
    dateReceived: "",
    deliverTerms: "",
    paymentTerms: "",
    paymentMethod: "",
    totalPrice: "",
    vendorId: "",
    prId: "",
    rfqId: "",
    ref: "",
    availability: "",
    validity: "",
    specialNotes: "",
    leadTime: "",
    comments: "",
    removedProducts: [],
    price: "",
    landedCost: "",
    filesToRemove: [],
    quantity: "",
    status: "",
    quoteDate: "",
    rfqRef: "",
    isDirectQuote: "",
    currencyId: "",
    currencyName: "",
    purchaser: "",
  });

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      //   supplierId: yup.string().required('Supplier is required'),
      //   validity: yup.string().required('Validity is required'),
      //   paymentTerm: yup.string().required('Payment Term is required'),
      //   leadTime: yup.string().required('Lead Time is required'),
    }),
    onSubmit: async (value, { resetForm }) => {
      delete value.price;
      delete value.landedCost;

      delete value.quantity;
      console.log("values", remove);

      let newItems =
        prlist.map((i) => ({ ...i, leadTime: i?.leadTime?.value || "" })) || [];
      console.log("submit value", newItems);
      let formData = new FormData();
      if (data?.vendor) {
        var valueWithItems = {
          ...value,
          isDirectQuote: false,
          products: newItems,
          removedProducts: remove,
          filesToRemove: fileRemoveList,
        };
      } else {
        valueWithItems = {
          ...value,
          rfqId: data?.rfqId || "",
          isDirectQuote: false,
          products: newItems,
          removedProducts: remove,
          filesToRemove: fileRemoveList,
        };
      }
      // console.log("formvalue",valueWithItems);
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      axiosInstance
        .put(`/supplier-quotations/edit-quotation/${data.id}`, formData)
        .then((res) => {
          if (res.data?.status === true) {
            // res.data?.data && setData();
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Created Successfully.",
            });
            resetForm();
            navigate(from, { state: { active: 2 } });
          } else if (res.data?.status === false && res.data?.error) {
            toast.open({
              type: "error",
              message: "Error",
              description: res.data.error || "Network error.",
            });
          } else {
            toast.open({
              type: "warning",
              message: "Warning",
              description: res.data?.msg || "Warning.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.open({
            type: "error",
            message: "Error",
            description: "Network error.",
          });
        });
    },
  });

  const getSupQuote = () => {
    axiosInstance
      .get(`/supplier-quotations/${sqEditId}`)
      .then((res) => {
        let TempData = res?.data?.data || {};
        TempData &&
          setCreateNew({
            approvalStatus: TempData?.approvalStatus,
            dateReceived: TempData.dateReceived,
            deliverTerms: TempData.deliverTerms,
            paymentTerms: TempData.paymentTerms,
            paymentMethod: TempData.paymentMethod,
            totalPrice: TempData.totalPrice,
            vendorId: TempData.vendorId,
            prId: TempData.prId,
            rfqId: TempData.rfqId,
            ref: TempData.ref,
            availability: TempData.availability,
            validity: TempData.validity,
            specialNotes: TempData.specialNotes,
            leadTime: TempData.leadTime,
            comments: TempData.comments,
            removedProducts: [],
            price: TempData.price,
            landedCost: TempData.landedCost,
            filesToRemove: [],
            quantity: TempData.quantity,
            status: TempData.status,
            quoteDate: TempData.quoteDate,
            rfqRef: TempData.rfqRef,
            isDirectQuote: "",
            currencyId: TempData?.currencyId,
            currencyName: TempData?.currencyName,
            purchaser: TempData?.purchaser,
            exchangeRate: TempData?.exchangeRate,
            lCost: TempData?.lCost,
            others: TempData?.others,
            boxTotal: TempData?.boxTotal,

          });
        setData(TempData);
        let newItems = TempData?.quoteProducts?.map((i) => ({
          ...i,
          leadTime: { value: i?.leadTime, label: i?.leadTime },
        }));
        setPrlist(newItems);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
      return null;
    });
    let tempArr = [];
    setSelectedFile(fileArr);

    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
      return null;
    });
    setImage(tempArr);
  }

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
  }

  useEffect(() => {
    getSupQuote();
  }, [sqEditId]);

  const [prods, setProds] = useState({
    sno: "",
    productId: "",
    isAlternate: "",
    leadTime: "",
    quantity: "",
    price: "",
    product_unit: "",
    dutty:"",
    trans:"",
    sellingPrice:"",
  });

  const productForm = useFormik({
    enableReinitialize: true,
    initialValues: prods,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({}),
    onSubmit: (value, { resetForm }) => {
      // const updatedPrlist = prlist;
      // updatedPrlist.push(value);
      // setPrlist(updatedPrlist);
      // resetForm();
    },
  });

  const handleAdd = async (index) => {
    if (
      productForm.values.productId &&
      productForm.values.quantity &&
      productForm.values.price
    ) {
      const selectedProduct = Item.find(
        (i) => i.value === productForm.values.productId
      );
      let lead_time = {
        label: productForm.values.leadTime,
        value: productForm.values.leadTime,
      };

      // Calculate new sno based on the existing item
      let newSno;
      if (formik.values.isDirectQuote) {
        newSno = prlist.length + 1;
      } else {
        const baseSno = prlist[index].sno;
        const subIndex = prlist.filter((item) =>
          String(item.sno).startsWith(baseSno)
        ).length;
        newSno = `${baseSno}${String.fromCharCode(64 + subIndex)}`; // 97 is ASCII code for 'a'
      }

      const newItem = {
        sno: newSno,
        productId: productForm.values.productId,
        leadTime: lead_time,
        price: parseFloat(productForm.values.price).toFixed(2),
        convPrice: parseFloat(
          productForm.values.price *
            (formik.values.exchangeRate > 0 ? formik.values.exchangeRate : 1)
        ).toFixed(2),
        isAlternate: formik.values.isDirectQuote ? false : true,
        product_name: selectedProduct.label,
        // product_unit: selectedProduct.unit,
        product_unit: productForm?.values.product_unit,
        landedCost: parseFloat(productForm.values.landedCost).toFixed(2),
        notes: productForm.values.notes,
        dutty: productForm.values.dutty,
        trans: 0.00,
        quantity: productForm.values.quantity,
      };

      if (formik.values.isDirectQuote) {
        setPrlist([...prlist, newItem]);
      } else {
        let NewValue = prlist[index].sno || "";

        let preFilter = prlist.map((item) => {
          let tempvalue = { ...item };
          tempvalue.sno = `${item.sno}`;
          return tempvalue;
        });

        let Filter =
          preFilter.filter((item) => item.sno.includes(`${NewValue}`)).length ||
          0;
        let newPrList = [...prlist];
        newPrList.splice(index + Filter, 0, newItem);
        setPrlist(newPrList);
        handleTransNewPR(newPrList);

        // setData(updatedItems);
      }

      // Clear the form fields
      productForm.values.productId = "";
      productForm.values.quantity = "";
      productForm.values.isAlternate = "";
      productForm.values.leadTime = "";
      productForm.values.price = "";
      productForm.values.convPrice = "";
      productForm.values.trans = "";
      productForm.values.dutty = "";
      productForm.values.sellingPrice = "";

      productForm.values.landedCost = "";
      productForm.values.product_unit = "";
      if (formik.values.isDirectQuote === false) {
        setShowForm(!showForm);
      }
      await updateTotalPrice();
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleForm = (idx) => {
    setShowForm(!showForm);
    setSelectedProductIndex(idx);
  };

  const handleQuantityChange = (index, newQuantity, isDataa = true) => {
    if (isDataa) {
      const updatedDataa = [...prlist];
      updatedDataa[index].quantity = newQuantity;
      setPrlist(updatedDataa);
      handleTrans();

    } else {
    }
    updateTotalPrice();
  };

  const updateTotalPrice = async () => {
    // console.log("calling upprice", prlist);

    const totalPrice = prlist.reduce((total, item) => {
      // return  (parseFloat(item.quantity)*parseFloat(item.convPrice) )+ parseFloat(item?.landedCost|| 0);
      return (
        (total || 0.0) +
        (parseFloat(item.quantity) * parseFloat(item.convPrice || 0.0) +
          parseFloat(item.landedCost || 0.0))
      );
    }, 0);
    formik.setFieldValue("totalPrice", totalPrice);
  };

  const handleIncrement = (index, isDataa = true) => {
    const newQuantity = prlist[index].quantity + 1;
    handleQuantityChange(index, newQuantity, isDataa);
  };

  const handleDecrement = (index, isDataa = true) => {
    const newQuantity = prlist[index].quantity - 1;
    handleQuantityChange(index, newQuantity, isDataa);
  };

  const handleInputChange = (event, index, isDataa = true) => {
    const value = Math.max(Number(event.target.value), 0);
    handleQuantityChange(index, value, isDataa);
  };

  const handleLeadTimeChange = (value, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].leadTime = value;
    setPrlist(updatedItems);
  };

  const handleProductUnitChange = (value, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].product_unit = value?.value;
    setPrlist(updatedItems);
  };
  const calcTransPerc = ()=>{
    const sumOfPriceQty = prlist?.reduce((total, item) => {
      return (
        (total || 0.00) +
        (Number(item.convPrice || 0)) * parseFloat(item.quantity)
      );
    }, 0);

    const computedStoreValue = parseFloat(
      (sumOfPriceQty > 0
        ? Number(formik.values.boxTotal || 0.00) / sumOfPriceQty
        : 0.00) * 100 * 0.01
    ).toFixed(2);
    return computedStoreValue;
  }

  const handleTrans = ()=>{
    const computedStoreValue = calcTransPerc();
    
    const updatedItems = prlist.map((item) => ({
      ...item, // Spread the existing properties
      trans: parseFloat(
        (item.convPrice || 0.00) *
        parseFloat(computedStoreValue > 0 ? computedStoreValue : 0)
      ).toFixed(2),
      landedCost: parseFloat(((Number(item.convPrice || 0)/100) * Number(item.dutty || 0)) + Number((item.convPrice || 0.00) *
      parseFloat(computedStoreValue > 0 ? computedStoreValue : 0))).toFixed(2)
    }));
  
    // Update the state with the new list
    setPrlist(updatedItems);
  };

  const calcTransPercNew = (newPR)=>{
    const sumOfPriceQty = newPR?.reduce((total, item) => {
      return (
        (total || 0.00) +
        (Number(item.convPrice || 0)) * parseFloat(item.quantity)
      );
    }, 0);

    const computedStoreValue = parseFloat(
      (sumOfPriceQty > 0
        ? Number(formik.values.boxTotal || 0.00) / sumOfPriceQty
        : 0.00) * 100 * 0.01
    ).toFixed(2);
    return computedStoreValue;
  }

  const handleTransNewPR = (newPR)=>{
    const computedStoreValue = calcTransPercNew(newPR);
    
    const updatedItems = newPR.map((item) => ({
      ...item, // Spread the existing properties
      trans: parseFloat(
        (item.convPrice || 0.00) *
        parseFloat(computedStoreValue > 0 ? computedStoreValue : 0)
      ).toFixed(2),
      landedCost: parseFloat(((Number(item.convPrice || 0)/100) * Number(item.dutty || 0)) + Number((item.convPrice || 0.00) *
      parseFloat(computedStoreValue > 0 ? computedStoreValue : 0))).toFixed(2)
    }));

    console.log('New PR List....', updatedItems);
    
  
    // Update the state with the new list
    setPrlist(updatedItems);
  };

  
  const handlePriceChange = (e, index, isDataa = true) => {
    const value = e.target.value;
    if (isDataa) {
      const updatedDataa = [...prlist];
      updatedDataa[index].price = value;
      updatedDataa[index].convPrice =
        value *
        parseFloat(
          formik.values.exchangeRate > 0 ? formik.values.exchangeRate : 1
        );
      // setData(updatedDataa);
    } else {
      // const updatedItems = [...items];
      // updatedItems[index].price = value;
      // updatedItems[index].convPrice = value/parseFloat(formik.values.exchangeRate>0?formik.values.exchangeRate:1);
      // setItems(updatedItems);
    }
    updateTotalPrice();
    handleTrans();

  };
  const handleCostChange = (e, index, isDataa = true) => {
    const value = e.target.value;
    if (isDataa) {
      const updatedDataa = [...prlist];
      console.log("bbb", updatedDataa);
      updatedDataa[index].landedCost = value;
      setPrlist(updatedDataa);
    } else {
      // const updatedItems = [...items];
      // updatedItems[index].landedCost = value;
      // setItems(updatedItems);
    }
  };

  const handleduttyChange = (e, index) => {   
    const updatedList = [...prlist];
    updatedList[index].dutty = e.target.value || 0;
    updatedList[index].landedCost = parseFloat(((Number(updatedList[index].convPrice || 0)/100) * Number(e.target.value || 0)) + Number(updatedList[index].trans)).toFixed(2);
    setPrlist(updatedList); 
  };

  const handleDelete = (quotProductId) => {
    let deletedProduct = prlist.find(
      (item) => item.quotProductId !== quotProductId
    );
    // console.log(beforeDelete);

    const updatedquoteProducts = prlist.filter(
      (item) => item.quotProductId !== quotProductId
    );
    console.log("testing", updatedquoteProducts);

    // const deletedProduct = data?.quoteProducts.find(item => item.quotProductId == quotProductId);

    // console.log("deleted pro", deletedProduct);

    if (deletedProduct) {
      const priceToRemove = deletedProduct.price || 0;
      const landedCostToRemove = deletedProduct.landedCost || 0;

      const quantityToRemove = deletedProduct.quantity || 0;
      const reduction = landedCostToRemove + priceToRemove * quantityToRemove;

      const newTotalPrice = formik.values.totalPrice - reduction;

      setPrlist(updatedquoteProducts);

      console.log("New List of Item", prlist);

      formik.setFieldValue("totalPrice", newTotalPrice);

      updateTotalPrice();
      setRemove([...remove, quotProductId]);
      console.log("remove list", remove);
    }
  };

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);

      // setRemoveFilePathList(data?.attachments);
    }
  }, [data]);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
        tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName);
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    });
    setImage(tempArr);
  };

  const [exChange, setExchage] = useState("");
  function exchangeRate(id) {
    axiosInstance
      .get(`/currency/${id}`)
      .then((res) => {
        //  console.log("currency",res?.data?.data?.exchangeRate);
        setExchage(res?.data?.data?.exchangeRate);
        formik.setFieldValue(
          "exchangeRate",
          res?.data?.data?.exchangeRate > 0
            ? res?.data?.data?.exchangeRate
            : formik?.values?.exchangeRate
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (formik.values.exchangeRate > 0) {
      const updatedList = prlist.map((item) => ({
        ...item,
        convPrice: parseFloat(item.price * formik.values.exchangeRate).toFixed(
          2
        ),
      }));

      // Assuming you want to set this updated list somewhere
      setPrlist(updatedList); // If you have a state to update prlist
    }
  }, [formik.values.exchangeRate]);
  useEffect(() => {
    //  getCurrenctFind(formik?.values?.currencyId)
    formik?.values?.currencyId && exchangeRate(formik?.values?.currencyId);
  }, [formik?.values?.currencyId]);

  useEffect(() => {
    console.log("working in last use");

    const totalPrice = prlist.reduce((total, item) => {
      return (
        (total || 0) +
        (Number(item.convPrice || 0) + Number(item.landedCost || 0)) *
          parseFloat(item.quantity)
      );
      // return total + (parseFloat(item.price)+parseFloat(item.landedCost) )* parseFloat(item.quantity || 0);
    }, 0);

    formik.setFieldValue("totalPrice", totalPrice);
  }, [prlist]);


  useEffect(() => {
    const calculateBoxTotal = () => {
      const lCost = formik.values.lCost ? parseFloat(formik.values.lCost) : 0.00;
      const others = formik.values.others ? parseFloat(formik.values.others) : 0.00;
  
      return parseFloat(lCost + others).toFixed(2);
    };
  
    const boxTotal = calculateBoxTotal();
  
    if (formik.values.boxTotal !== boxTotal) {
      formik.setFieldValue('boxTotal', boxTotal);
    }
  }, [formik.values.lCost, formik.values.others]);
  
      
  useEffect(()=>handleTrans(),[formik.values.boxTotal]);

  const handleNoteChange = (e, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].notes = e.target.value;
    setPrlist(updatedItems);

  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const popupCalculate = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="w-full h-full py-2 px-4 overflow-hidden z-[8]">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            { name: "RFQ", link: "/procurement/rfq/" },
            { name: "Edit Quote" },
          ]}
        />
        <div className="flex gap-2">
          <Link to={from} state={{ active: 2 }}>
            <button
              className="px-3 h-8 cursor-pointer text-xs 2xl:text-sm text-textColor-main bg-white rounded-md flex items-center"
              style={{ border: "1px solid #048178" }}
            >
              <span className="mr-2 flex justify-center items-center">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.898 7.70773H4.42869L9.43946 2.69696C9.83991 2.29651 9.83991 1.63936 9.43946 1.23891C9.34447 1.14372 9.23163 1.0682 9.10742 1.01668C8.9832 0.965151 8.85004 0.938629 8.71557 0.938629C8.58109 0.938629 8.44793 0.965151 8.32372 1.01668C8.1995 1.0682 8.08667 1.14372 7.99167 1.23891L1.22509 8.0055C1.1299 8.10049 1.05438 8.21333 1.00285 8.33754C0.951327 8.46176 0.924805 8.59491 0.924805 8.72939C0.924805 8.86387 0.951327 8.99703 1.00285 9.12124C1.05438 9.24546 1.1299 9.35829 1.22509 9.45328L7.99167 16.2199C8.08674 16.3149 8.19959 16.3903 8.3238 16.4418C8.448 16.4932 8.58113 16.5197 8.71557 16.5197C8.85001 16.5197 8.98313 16.4932 9.10733 16.4418C9.23154 16.3903 9.3444 16.3149 9.43946 16.2199C9.53452 16.1248 9.60993 16.012 9.66138 15.8877C9.71282 15.7635 9.7393 15.6304 9.7393 15.496C9.7393 15.3615 9.71282 15.2284 9.66138 15.1042C9.60993 14.98 9.53452 14.8672 9.43946 14.7721L4.42869 9.76132H15.898C16.4627 9.76132 16.9248 9.29926 16.9248 8.73452C16.9248 8.16979 16.4627 7.70773 15.898 7.70773Z"
                    fill="#048178"
                  />
                </svg>
              </span>
              Back
            </button>
          </Link>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className="w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto">
            <NuInput
              disabled={true}
              label="Quotation Reference"
              type="text"
              formik={formik}
              placeholder="Enter Reference"
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {!data?.vendor && (
              <FormikSelectTwo
                type="text"
                label="Supplier"
                placeholder="Choose"
                Options={suppliers}
                formik={formik}
                name="vendorId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
            )}
            <NuDate
              label="Quotation Received Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dateReceived"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Quotation Validity"
              placeholder="Validity"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Delivery Terms"
              placeholder="Delivery Terms"
              Options={DeliveryTerms}
              formik={formik}
              name="deliverTerms"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Payment Terms"
              placeholder="Payment Terms"
              Options={PaymentTerms}
              formik={formik}
              name="paymentTerms"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Currency"
              placeholder="Choose"
              Options={currency}
              formik={formik}
              name="currencyId"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuInput
              label="Exchange Rate"
              type="text"
              formik={formik}
              placeholder="Reference"
              name="exchangeRate"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={exChange === "" || !exChange ? false : true}
            />

            <FormikSelectTwo
              type="text"
              label="Payment Method"
              placeholder="Payment Method"
              Options={paymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Status"
              placeholder="Choose"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refStatus}
              displayProperty={"Status"}
              propertyName={"Status_Supplierqoutes"}
            />
            {/* <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Special Notes From Supplier"
              placeholder="Special Notes From Supplier"
              formik={formik}
              name="specialNotes"
              width="w-full md:w-1/2 lg:w-1/3"
              new_width="w-full md:w-1/2 lg:w-2/3"
            />
            {/* <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <div className="w-full">
              <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                SQ Attachement :
              </p>
            </div>

            <div className="md:flex justify-between  px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList?.map((imgs, idx) => {
                    console.log("img", imgs);
                    let Ext = imgs?.file_name.split(".").reverse()[0];
                    return (
                      <div
                        className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          <CloseIcon color="white" height="8" width="8" />
                        </div>
                        <div
                          className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          <div className=" w-8 h-8">
                            {videotype.includes(Ext) ? <Video /> : null}

                            {Ext === ("docx" || "doc" || "txt") ? (
                              <Doc />
                            ) : Ext === ("xlsx" || "xls") ? (
                              <Xls />
                            ) : Ext === "txt" ? (
                              <Txt />
                            ) : Ext === "pdf" ? (
                              <Pdf />
                            ) : Ext === "pptx" ? (
                              <>No file</>
                            ) : (
                              <img src={imgs?.file} alt="#" width="120%" />
                            )}
                          </div>
                          <p className=" w-[50px] text-[11px] truncate">
                            {imgs?.file_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                  removeImages={removeImages}
                />
              </div>
            </div>
            {/* </div> */}
            <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            {missingProducts?.length > 0 && (
              <div className="w-full flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between gap-3 border border-solid">
                <FormikSelectTwo
                  type="text"
                  label="Product"
                  placeholder="Product"
                  Options={missingProducts}
                  formik={formik}
                  name="productId"
                  width="w-[90%]"
                />
                <button
                  onClick={handleAdd}
                  type="button"
                  className=" p-1 mt-10 m-1 text-center h-10 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg "
                >
                  Add
                </button>
              </div>
            )}
            <div className="w-full bg-white overflow-hidden mt-5 ">
              {(showForm || formik.values.isDirectQuote) && (
                <div className="w-full rounded-md flex flex-col px-2 md:flex-row gap-y-3 md:gap-y-0 justify-start items-end gap-1 border border-solid">
                  {/* <FormikSelectTwo
                  type="text"
                  label="Product "
                  placeholder="Product "
                  Options={Item}
                  formik={productForm}
                  name="productId"
                  width="w-full md:w-[70%] lg:w-[50%] "
                  px="3"
                /> */}
                <div className="md:w-1/2 lg:w-[40%] z-[5]">
                <SelectProductWithError
                    type="text"
                    label="Product "
                    placeholder="Product "
                    Options={Item}
                    formik={productForm}
                    name="productId"
                    width="w-full "
                    ErrMessage={""}
                    px="0"
                    handleClick={togglePopup}
                    hasAddButton={false}
                    setSearch={setSearch}
                    FixPosition={false}
                  />
                </div>


                  {/* <button
                  onClick={togglePopup}
                  type="button"
                  className="px-3 mb-2 ml-2 text-center py-1.5 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg"
                >
                  {" "}
                  +{" "}
                </button> */}
                <div className="md:w-1/2 lg:w-1/4 z-[5]">
                  <FormikSelectTwo
                    type="text"
                    label="Unit"
                    placeholder="Choose "
                    Options={Unit}
                    formik={productForm}
                    name="product_unit"
                    width="w-full "
                    px="2"
                    FixPosition={false}
                  />
</div>
                  <NuInput
                    label="Quantity"
                    type="number"
                    formik={productForm}
                    placeholder="Quantity"
                    name="quantity"
                    px="2"
                    width="w-full md:w-1/2 lg:w-1/4 "
                  />
                  <div className="md:w-1/2 lg:w-1/4 z-[5]">
                  <FormikSelectTwo
                    type="text"
                    label="Lead time"
                    placeholder="Choose "
                    Options={LeadTime}
                    formik={productForm}
                    name="leadTime"
                    width="w-full"
                    px="2"
                  />
                  </div>
                  <NuInput
                    label="Price"
                    type="number"
                    formik={productForm}
                    placeholder="0.00"
                    name="price"
                    px="2"
                    width="w-full md:w-1/2 lg:w-1/4"
                  />
                   <NuInput
                  label="Dutty"
                  type="number"
                  formik={productForm}
                  placeholder="0.00"
                  name="dutty"
                  px="2"
                  width="w-full md:w-1/2 lg:w-1/4"
                  // isRequired={true}
                />
                  <button
                    type="button"
                    onClick={() => handleAdd(selectedProductIndex)}
                    className="px-2 mb-2 text-center py-1.5 cursor-pointer bg-[#048178] border border-solid border-white  text-white text-sm font-semibold rounded-lg "
                  >
                    Add
                  </button>
                </div>
              )}
              <div
                className={`w-[calc(100vw-280px)] h-[calc(100vh-190px)] overflow-y-auto overflow-x-auto relative`}
              >
                <table className="table-auto relative  border-collapse bg-white mt-2 z-0">
                  <thead className="bg-baseColor-primary sticky top-0 text-white text-xs 2xl:text-sm z-[3] ">
                    <tr>
                      <th className=" sticky  left-0 bg-baseColor-primary z-[3]  h-10    ">
                        <div className=" flex  w-full  items-center">
                          <div className=" w-[80px]   flex justify-center items-center text-center ">
                            S.N.
                          </div>
                          <div className=" w-[300px]  flex  pl-2 text-left ">
                            Product
                          </div>
                        </div>
                      </th>
                      <th className="  ">
                        <div className=" flex  w-full  items-center">
                          <div className=" w-[200px]   flex justify-center items-center text-center ">
                            Unit
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Quantity
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Lead Time
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Price
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Conv. Price
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Duty
                            <p
                              onClick={popupCalculate}
                              className="cursor-pointer p-1"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="white"
                                class="size-4"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z"
                                />
                              </svg>
                            </p>
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Transportation + Exp
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Landing Cost
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Selling Price
                          </div>
                          <div className=" w-[200px]  flex justify-center items-center text-center ">
                            Total
                          </div>
                          <div className=" w-[70px]  flex justify-center items-center text-center ">
                            Action
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {prlist?.length > 0 ? (
                    <tbody className=" h-[calc(100vh-300px)] 2xl:h-[calc(100vh-500px)] overflow-y-scroll z-[0]">
                      {prlist &&
                        prlist.map((List, index) => {
                          return (
                            <tr
                              key={index}
                              className="  text-xs 2xl:text-sm border-collapse border border-slate-300"
                            >
                              <td
                                className={` text-center sticky left-0  z-[1] `}
                              >
                                <div className=" flex  items-center min-h-16">
                                  <div className=" w-[80px] text-center min-h-16 bg-white  flex justify-center items-center">
                                    {List?.isAlternate == false &&
                                      formik.values.isDirectQuote == false && (
                                        <button
                                          type="button"
                                          onClick={() => toggleForm(index)}
                                          className="font-semibold px-1.5 py-0.5 bg-blue-600 text-white rounded-lg ml-[-5px] mr-1"
                                        >
                                          <p>+</p>
                                        </button>
                                      )}
                                    {List?.sno || "-"}
                                  </div>
                                  <div className=" w-[300px] bg-white  text-left line-clamp-2 min-h-16   px-2 items-center">
                                    <p className="text-left  text-xs font-light p-1">
                                      {List?.product_name}
                                    </p>
                                    {!List?.isAlternate && (
                                      <p className="flex">
                                        <p className="text-[12px]  font-thin mt-1">
                                          {" "}
                                          Notes :{" "}
                                        </p>
                                        <input
                                          type="text"
                                          className="w-[65%] py-1 text-left px-1  border border-gray-100 text-xs font-thin "
                                          name="reason"
                                          placeholder="Enter here ..."
                                          value={List.notes || ""}
                                          onChange={(e) =>
                                            handleNoteChange(e, index)
                                          }
                                        />
                                      </p>
                                    )}
                                    {List?.isAlternate ? (
                                      <p className="text-left text-[#65667b] text-[10px] md:text-[10px] lg:text-[8px] xl:text-[10px] font-light">
                                        (Alternate)
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="">
                                <div className="w-full">
                                  <td
                                    className={`py-2 text-center text-xs font-light  w-[200px] px-2 `}
                                  >
                                    <div className="  ">
                                      <Select
                                        value={
                                          Unit.find(
                                            (item) =>
                                              item?.value == List.product_unit
                                          ) || List.product_unit
                                        }
                                        options={Unit}
                                        className="w-full"
                                        onChange={(value) =>
                                          handleProductUnitChange(value, index)
                                        }
                                        // isDisabled={!isEditing}
                                      />
                                    </div>
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light  w-[200px] px-2 `}
                                  >
                                    <button
                                      type="button"
                                      className="border rounded-full p-1 m-1"
                                      onClick={() => handleDecrement(index)}
                                    >
                                      -
                                    </button>
                                    <input
                                      type="number"
                                      className="w-[20%] text-center border  text-xs font-light"
                                      name="quantity"
                                      value={List.quantity}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                    />
                                    <button
                                      type="button"
                                      className="border rounded-full p-1 m-1"
                                      onClick={() => handleIncrement(index)}
                                    >
                                      +
                                    </button>
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light border-collapse  w-[200px] px-2 `}
                                  >
                                    <div className="  ">
                                      <Select
                                        value={
                                          LeadTime.find(
                                            (item) =>
                                              item?.value == List.leadTime
                                          ) || List.leadTime
                                        }
                                        options={LeadTime}
                                        className="w-full"
                                        onChange={(value) =>
                                          handleLeadTimeChange(value, index)
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light w-[200px] px-2 `}
                                  >
                                    <input
                                      type="number"
                                      className=" w-full py-2 rounded  text-center border  text-xs font-light"
                                      name="price"
                                      formik={formik}
                                      placeholder="Enter the price"
                                      value={List.price || ""}
                                      onChange={(e) =>
                                        handlePriceChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light w-[200px] px-2`}
                                  >
                                    <div className="text-center text-xs font-light">
                                      {/* {List.convPrice} */}
                                      {List?.convPrice?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                      <p className="text-[8px]">
                                        Total:
                                        <span className="text-red-900">
                                          {(
                                            List?.quantity * List?.convPrice
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </span>
                                      </p>
                                    </div>
                                  </td>

                                  <td
                                    className={`py-2 text-center text-xs font-light w-[200px] px-2 `}
                                  >
                                     <>
                               <div className="flex items-center justify-between">

                                      
      <div className="px-1">
        <input
          type="number"
          className="w-[90%] py-1 text-center border text-xs font-light"
          name="dutty"
          placeholder="Enter the duty"
          value={List.dutty || ""}
          onChange={(e) => handleduttyChange(e, index)}
        />
        
      </div>
    
  </div>

  <p className="text-[8px] text-gray-600">
    Duty:{" "}
    <span className="text-red-900">
      (
      {((List?.convPrice / 100) * (List?.dutty || 0)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
      )
    </span>
  </p>
  
  <p className="text-[8px] text-center text-gray-600">
    Total:{" "}
    <span className="text-red-900">
      {(
        List?.quantity *
        ((List?.convPrice / 100) * (List?.dutty || 0))
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </span>
  </p>

</>
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light w-[200px] px-2 `}
                                  >
                                    <div className="  ">
                                    {/* {List.trans} */}
                                    {List?.trans?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                <p className="text-[8px]">
                                  Total:
                                  <span className="text-red-900">
                                    {(
                                      List?.quantity * List?.trans
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                    </span>
                                    </p>
                                    </div>
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light w-[200px] px-2 `}
                                  >
                                    <div className="  ">
                                    
                                   {(List.landedCost).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                    </div>
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light w-[200px] px-2 `}
                                  >
                                    <div className="  ">
                                    {(
                                  (parseFloat(List?.convPrice) +
                                    parseFloat(List?.landedCost)) 
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                    </div>
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light  w-[200px] px-2 `}
                                  >
                                    <div className="  ">
                                      {(
                                        (parseFloat(List.convPrice) +
                                          parseFloat(List.landedCost)) *
                                        List.quantity
                                      ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }) || 0}
                                     
                                    </div>
                                  </td>
                                  <td
                                    className={`py-2 text-center text-xs font-light  w-[70px] px-2 border-slate-300`}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(List?.quotProductId)
                                      }
                                      type="button"
                                      className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                                    >
                                      <DeleteIcon color="red" height={4} />
                                    </button>
                                  </td>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : (
                    // {prlist?.length>0?

                    // <div className="h-[calc(100vh-300px)] overflow-y-scroll">

                    // {prlist && prlist.map((List, index) => {
                    //   return (
                    //     <div
                    //       key={index}
                    //       className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
                    //     >
                    //       <p className=" w-[5%] text-left pl-1   text-xs ">
                    //         {List?.isAlternate == false && formik.values.isDirectQuote == false && (
                    //           <button
                    //             type="button"
                    //             onClick={() => toggleForm(index)}
                    //             className="font-semibold px-1.5 py-0.5 bg-blue-600 text-white rounded-lg ml-[-5px] mr-1"
                    //           >
                    //             <p>+</p>
                    //           </button>
                    //         )}
                    //         {List?.sno || "-"}
                    //       </p>
                    //       <div className="w-[35%]">
                    //         <p className="text-left  text-xs font-light">
                    //           {List?.product_name}
                    //         </p>
                    //         {!List?.isAlternate && (
                    //         <p className="flex">
                    //          <p className="text-[12px]  font-thin mt-1"> Notes :</p>
                    //   <input
                    //                           type="text"
                    //                           className="w-[65%] py-1 text-left px-1  border border-gray-100 text-xs font-thin "
                    //                           name="reason"
                    //                           placeholder="Enter here ..."
                    //                           value={List.notes || ""}
                    //                           onChange={(e) => handleNoteChange(e, index)}
                    //                         />
                    //                         </p>
                    //         )}
                    //         {List?.isAlternate ? (
                    //           <p className="text-left text-[#65667b] text-[10px] md:text-[10px] lg:text-[8px] xl:text-[10px] font-light">
                    //             (Alternate)
                    //           </p>
                    //         ) : (
                    //           <></>
                    //         )}
                    //       </div>
                    //       <p className=" w-[10%] text-center  text-xs font-light">
                    //         {/* {List?.product_unit} */}
                    //         <Select
                    //           value={
                    //             Unit.find((item) => item?.value == List.product_unit) ||
                    //             List.product_unit
                    //           }
                    //           options={Unit}
                    //           className="w-full"
                    //           onChange={(value) => handleProductUnitChange(value, index)}
                    //           // isDisabled={!isEditing}
                    //         />
                    //       </p>
                    //       {/* <p className=" w-[20%] text-center  text-xs font-light ">
                    //         {List?.quantity}
                    //       </p> */}
                    //       <div className="w-[15%] text-center  text-xs font-light">
                    //         <button
                    //           type="button"
                    //           className="border rounded-full p-1 m-1"
                    //           onClick={() => handleDecrement(index)}
                    //         >
                    //           -
                    //         </button>
                    //         <input
                    //           type="number"
                    //           className="w-[20%] text-center border  text-xs font-light"
                    //           name="quantity"
                    //           value={List.quantity}
                    //           onChange={(e) => handleInputChange(e, index)}
                    //         />
                    //         <button
                    //           type="button"
                    //           className="border rounded-full p-1 m-1"
                    //           onClick={() => handleIncrement(index)}
                    //         >
                    //           +
                    //         </button>
                    //       </div>
                    //       <div className="w-[13%] px-2 text-center  text-xs font-light">
                    //         {/* {isEditing ? ( */}
                    //         <Select
                    //           value={
                    //             LeadTime.find((item) => item?.value == List.leadTime) ||
                    //             List.leadTime
                    //           }
                    //           options={LeadTime}
                    //           className="w-full"
                    //           onChange={(value) => handleLeadTimeChange(value, index)}
                    //         />
                    //       </div>
                    //       <div className="w-[15%] px-2 ">
                    //       <input
                    //           type="number"
                    //           className=" w-full py-2 rounded  text-center border  text-xs font-light"
                    //           name="price"
                    //           formik={formik}
                    //           placeholder="Enter the price"
                    //           value={List.price || ""}
                    //           onChange={(e) => handlePriceChange(e, index)}
                    //         />
                    //       </div>
                    //       <p className="text-center w-[15%]  text-xs py-1 font-light">
                    //         {List.convPrice}
                    //         <p className="text-[8px]">
                    //           Total:
                    //           <span className="text-red-900">
                    //             {(List?.quantity * List?.convPrice).toLocaleString(undefined, {
                    //               minimumFractionDigits: 2,
                    //               maximumFractionDigits: 2,
                    //             })}
                    //           </span>
                    //         </p>
                    //       </p>
                    //       <div className="w-[13%] px-2 ">
                    //         <input
                    //           type="number"
                    //           className=" w-full py-2 rounded  text-center border  text-xs font-light"
                    //           name="landedCost"
                    //           formik={formik}
                    //           placeholder="Enter the Cost"
                    //           value={List.landedCost || 0}
                    //           onChange={(e) => handleCostChange(e, index)}
                    //         />
                    //       </div>
                    //       <p className="text-center w-[13%]  text-xs font-light">
                    //         {(
                    //           ( parseFloat(List.convPrice) + parseFloat(List.landedCost) )* List.quantity
                    //         ).toLocaleString(undefined, {
                    //           minimumFractionDigits: 2,
                    //           maximumFractionDigits: 2,
                    //         })|| 0 }
                    //         {/* <span>{supplierCurrency}</span> */}

                    //         {/* {data?.label == "" ?
                    //    <NuAmount value={Number((List.price * List.quantity + parseFloat(List.landedCost)) || 0)} />
                    //    :
                    //    <NuAmount currency={supplierCurrency} value={Number((List.price * List.quantity + parseFloat(List.landedCost)) || 0)} />
                    // } */}
                    //       </p>
                    //       {/* <p className=" w-[15%] text-center  text-xs font-light">
                    //         {List?.price}
                    //       </p> */}
                    //       <div className="w-[7%] text-center">
                    //         <button
                    //           onClick={() => handleDelete(List?.quotProductId)}
                    //           type="button"
                    //           className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                    //         >
                    //           <DeleteIcon color="red" height={4} />
                    //         </button>
                    //       </div>
                    //     </div>
                    //   );
                    // })}
                    // </div>
                    <div className="w-full text-center text-[#93949f]">
                      No Items here
                    </div>
                  )}
                </table>
              </div>{" "}
            </div>
            <div className=" w-full my-6  flex justify-end items-center gap-4">
              <TextMediumBase content={"Total Price : "} />
              <p className="text-sm"> {currencySymbol} </p>
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                <NuAmount
                  defaultCurrency={false}
                  value={Number(formik?.values?.totalPrice || 0)}
                />
              </p>
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      {isPopupVisible && (
      <form onSubmit={formik.handleSubmit}>
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[35%] max-h-[70vh] bg-white py-4 px-4 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-lg font-medium text-[14px]">Duty</h2>

            {/* Landing Cost Input */}
            <div className="my-4 flex w-full gap-4">
              <label className="mt-4 text-sm font-medium text-gray-700 w-[30%]">Landing Cost</label>
              <p className="mt-3">:</p>
              <input
                type="text"
                name="lCost"
                value={formik.values.lCost}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="text-xs 2xl:text-sm font-medium border border-gray-300 w-[60%] h-10 px-3 mt-2 rounded-[10px] outline-none"
              />
            </div>

            {/* Other Details Input */}
            <div className="my-4 flex w-full gap-4">
              <label className="w-[30%] mt-4 text-sm font-medium text-gray-700">Other Details</label>
              <p className="mt-3">:</p>
              <input
                type="text"
                name="others"
                value={formik.values.others}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="text-xs 2xl:text-sm font-medium border border-gray-300 w-[60%] h-10 px-3 mt-2 rounded-[10px] outline-none"
              />
            </div>

            {/* Total (Read-Only) */}
            <div className="my-4 flex w-full gap-4">
              <label className="w-[30%] mt-4 text-sm font-medium text-gray-700">Total</label>
              <p className="mt-3">:</p>
              <input
                type="text"
                name="boxTotal"
                value={formik.values.boxTotal}
                disabled
                className="text-xs 2xl:text-sm font-medium border border-gray-300 w-[60%] h-10 px-3 mt-2 rounded-[10px] outline-none bg-gray-100"
              />
            </div>

            {/* Buttons */}
            <div className="flex justify-end gap-2">
              {/*<button
                type="button"
                onClick={popupCalculate}
                className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2] hover:bg-gray-300 rounded-md flex items-center mr-2 text-textColor-main"
              >
                Cancel
              </button> */}
              <button
                type="button"
                onClick={popupCalculate}
                className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </form>
)}
    </div>
  );
}

export default RFQEditSQ;
