import React, { useEffect, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import {
  NuDate,
  NuInput,
  NuInputWithError,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo, { SelectProductWithError, SelectWithError } from "../../../../Components/Utils/Select";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import UseEditData from "../../../../Components/Hooks/useEditData";
import * as yup from "yup";
import Dashboard from "../../../../Components/Icons/Dasboard";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import Delete from "../../../../Components/Icons/Delete";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseDropDownNew from "../../../../Components/Hooks/useDDNew";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement, { videotype } from "../../../../Components/Utils/CustomAttrachmentupload";
import Modal from "../../../../Components/Utils/AttachmentView";
import MultiSelect from "../../../../Components/Utils/MultiSelect";
import EditIcon from "../../../../Components/Icons/Edit";
import Video from "../../../../Components/Icons/Video";
import Doc from "../../../../Components/Icons/Doc";
import Xls from "../../../../Components/Icons/Xls";
import Txt from "../../../../Components/Icons/Txt";
import Pdf from "../../../../Components/Icons/Pdf";
import CloseIcon from "../../../../Components/Icons/Close";
import DropDownAddMetaData, { DropDownAddUnitWithError } from "../../../../Components/Utils/selectAddMetaData";
import UseProductDropDownNew from "../../../../Components/Hooks/useProductSearch";
import Select from 'react-select'
import { GetNotUseRole } from "../../../../Components/Utils/roles";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function EditPr() {
  const { value: status, getData:refStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "rfqStatus_pr"
  );
  const { value:Unit } = useFetchMataData(`/dropdown/dropdown-details/`,'Unit_item')
  // const { value: Costcode } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "costCode_pr"
  // );
  // const { value: approvalStatus } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "approval_status_pr"
  // );
  
  // const { data: Item, setData } = UseDropDownNew("/items/new-drop-down");
  const [search, setSearch] = useState("")
  const { data: Item, setData } = UseProductDropDownNew('/items/new-drop-down',search);

  const { data: RFQAssignee } = UseDropDown('/user/procurement/drop-down');
  const { data: Salesman } = UseDropDown('/user/salesman/drop-down');

  const { data: customers } = UseDropDown("/customers/drop-down");
  // const [loading, setLoading] = useState(false);

  const [image, setImage] = useState([]);
  const [fileRemoveList, setFileRemoveList] = useState([]);
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  console.log(location.state, "state");
  const [dataa, setDatas] = useState(data);
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [items, setItems] = useState([]);
  const [remove, setRemove] = useState([]);
  const  [isEditQua,setIsEditQua] = useState(false);
  const  [isEditUnit,setIsEditUnit] = useState(false);
  const [combinedItems, setCombinedItems] = useState([]);
  // console.log(items, "kk");
  // console.log(data, "pp");
  const [newItemData, setNewItemData] = useState({
    productId: "",
    quantity: "",
     product_unit:""
  });
  const [createNew, setCreateNew] = useState({
    customerId: data?.customerId,
    customerRfqRef: data?.customerRfqRef,
    deadLine: data?.deadLine,
    isActive: data?.isActive,
    notesFromCustomer: data?.notesFromCustomer,
    costCode: data?.costCode,
    salesPerson: data?.salesPerson,
    preparedBy: data?.preparedBy,
    reviewedBy: data?.reviewedBy,
    approvedBy: data?.approvedBy,
    reasonForPurchase: data?.reasonForPurchase,
    comments: data?.comments,
    status: data?.status,
    removedProducts: [],
    filesToRemove: [],
    approvalStatus:data?.approvalStatus,
    description:data?.description,
    rfqAssignee: data?.rfqAssigneeIds,
    attn: data?.attn,
    rfqAssigneeNames:[],
    // rfqAssigneeIds:data?.rfqAssigneeIds,
    // rfqAssigneeNames:data?.rfqAssigneeNames,
  });
  const handleDelete = (productId) => {
   
    const updatedProductList = dataa?.productList.filter(
      (item) => item.productId !== productId
    );
    // const deletedItem = dataa?.productList.find(item => item.productId === productId);
    console.log(updatedProductList, "lll");
   
    setDatas((prevState) => ({
      ...prevState,
      productList: updatedProductList,
    }));
    setRemove([...remove, productId]);

    // setremove(prevState => ({
    //   ...prevState,
    //   removedProducts: [...prevState.removedProducts, deletedItem]
    // }));
  };
       const [error, setError] = useState('');
       const [productError, setProductError] = useState('');
       const [unitError, setUnitError] = useState('');
       const [quantityError, setQuantityError] = useState('');
  const handleAdd = () => {
    let hasError = false;

    if (!formik.values.productId) {
      setProductError('Product is required');
      hasError = true;
    } else {
      setProductError('');
    }

    if (!formik.values.product_unit) {
      setUnitError('Unit is required');
      hasError = true;
    } else {
      setUnitError('');
    }

    if (!formik.values.quantity) {
      setQuantityError('Quantity is required');
      hasError = true;
    } else {
      setQuantityError('');
    }

    if (hasError) return;

    if (formik.values.productId && formik.values.quantity){
    const selectedProduct = Item.find(
      (i) => i.value === formik.values.productId
    );
    const newItem = {
      productId: formik.values.productId,
      productName: Item.find((i) => i.value === formik.values.productId),
      quantity: formik.values.quantity,
      // unit: selectedProduct.unit,
      product_unit: formik.values.product_unit
    };
    setItems([...items, newItem]);
   
    setNewItemData({
      productId: "",
      product_unit:"",
      quantity: "",
    });
    let updatedItems = Item.filter((it)=> it.value!==formik.values.productId)
    setData(updatedItems)
    formik.values.productId = "";
    formik.values.quantity = "";
    formik.values.product_unit = "";

    setError('')
  }
  };

  const handleQuantityChange = (e, index) => {
    const updatedQuantity = e.target.value;
  
    const updatedItems = items.map((item, idx) => {
      if (idx === index) {
        return { ...item, quantity: updatedQuantity };
      }
      return item;
    });
  
    setItems(updatedItems); 
  };

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = items.filter((_, index) => index !== indexToDelete);
    let ddUpdatedItem = items.find((_,i)=> i === indexToDelete)
       setItems(updatedItems);
    let tempDd = ddUpdatedItem.productName
    setData([...Item,tempDd])
    // localStorage.setItem("items", JSON.stringify(updatedItems));
  };

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew((prevState) => ({ ...createNew, image: "" }));
    formik.values.image = "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
        customerId: yup.string().required('Customer is required!'),
        salesPerson: yup.string().required('Sales person is required!'),
        customerRfqRef: yup.string().required('Customer RFQ Reference person is required!'),
        // division: yup.string().required('Division is required!')
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true); 
      if (items.length === 0 && data?.productList?.length===0) {
        setError('Product table is empty');
        return;
      }
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        productList: combinedItems,
        removedProducts: remove,
        filesToRemove: fileRemoveList,
      };
     
      formData.append("prDetails", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      editFormData(`/PR/edit-PR/${data.prId}`, formData, resetForm, from);
      // setLoading(false)
    },
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);
     
      setRemoveFilePathList(data?.attachments);
     
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }

  const selectedProductIds = [
    ...items.map((item) => item.productId),
    ...dataa.productList.map((product) => product.productId)
  ];
  
  // Step 2: Filter out the selected products from the dropdown options
  const filteredOptions = Item.filter(
    (product) => !selectedProductIds.includes(product.value)
  );

  const [finalList,setFinalList] = useState(dataa?.productList);

  const handleQuantityChangeAlredyExit = (e, index) => {
  const updatedItems = [...finalList];
  updatedItems[index].quantity = e.target.value;
  setFinalList(updatedItems);
  console.log("shsd",finalList);
};

const handleProductUnitChangeAlredyExit = (value, index) => {
  const updatedItems = [...finalList];
  updatedItems[index].product_unit = value?.value;
  setFinalList(updatedItems);
  console.log("shsd",finalList);
};

console.log("sss",dataa?.productList);
console.log("ddd",items);

useEffect(() => {
  if (dataa?.productList && items) {
    const mergedArray = [...dataa.productList, ...items];
    setCombinedItems(mergedArray);
  }
}, [dataa?.productList, items]);

console.log("fff",combinedItems);

function handleImageChange(e) {
  const maxFileSize = 200000000; // 200 MB
  const files = Array.from(e.target.files);
  let fileArr = [];
  files.map((item) => {
    if (item.size < maxFileSize) {
      fileArr.push(item);
    } else {
      toast.open({
        type: "warning",
        message: `File Size to big`,
        description: `${item.name} is Removed and not Uploaded`,
        // placement: "bottomRight",
      });
    }
  });
  let tempArr = [];
  setSelectedFile(fileArr);
  files.map((item) => {
    if (item.size < maxFileSize) {
      let fileName = item?.name;
      console.log("fname", fileName);
      // let fileExtention = fileName.split(".").reverse()[0];
      // if (filetype?.includes(fileExtention)) {
        tempArr.push(fileName);
      // } else {
      //   let x = URL.createObjectURL(item);
      //   tempArr.push(x);
      // }
    }
  });
  console.log(tempArr, "temp");
  setImage(tempArr);
}

const removeImages = (value) => {
  console.log("value", value);
  let List = selectedFile.filter((item) => item?.name !== value);
  console.log("LIst", List);
  setSelectedFile([...List]);
  let tempArr = []
  List.map((item) =>{
    let fileName = item?.name
    // console.log('fname',fileName);
    // let fileExtention =  fileName.split(".").reverse()[0]
    // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName)
    // }else{
    //   let x = URL.createObjectURL(item)
    //   tempArr.push(x)
    // }
  })
  setImage(tempArr)
};

// const filterdItem = Item.filter(item => item?.value !==  dataa?.productList?.productId)
console.log("findingdrop",Item,dataa?.productList);
const filterdItem = Item.filter(drop => 
  !dataa?.productList?.some(id => id.productId === drop.value)
)

const handleProductUnitChange = (value, index) => {
  const updatedItems = items.map((item, idx) => {
    if (idx === index) {
      return { ...item, product_unit: value?.value };
    }
    return item;
  });
  setItems(updatedItems);
};
console.log("after",filterdItem);  
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            { name: "Procurement" },
            {
              name: "YPR",
              link: "/procurement/purchase-requests/",
            },

            { name: "Edit" },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">

<form onSubmit={formik.handleSubmit}>

<div className="w-full mx-auto bg-white rounded-md pb-4 pt-2">
  <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
          
                   {/* <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  YPR:
  </p>
</div>     */}
  <NuInput
              label="Description"
              type="text"
              formik={formik}
              placeholder="Description"
              name="description"
              width="w-full md:w-1/2 lg:w-[66%]"
            />
           {
            GetNotUseRole("procurement") &&
            <FormikSelectTwo
              disabled={true}
              type="text"
              label="Customer "
              placeholder="Customer "
              Options={customers}
              formik={formik}
              name="customerId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
           } 
            <NuInput
              disabled={true}
              label="Customer RFQ Reference"
              type="text"
              formik={formik}
              placeholder="Customer Reference"
              name="customerRfqRef"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <MultiSelect
              // type="text"
              label="RFQ Assignee"
              placeholder="RFQ Assignee"
              Options={RFQAssignee}
              formik={formik}
              name="rfqAssignee"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <FormikSelectTwo
              type="text"
              label="Division"
              placeholder="Choose Division"
              Options={[
                {
                  value: "Management",
                  label: "Management",
                },
                {
                  value: "Coordination",
                  label: "Coordination",
                },
                {
                  value: "Procurement",
                  label: "Procurement",
                },
                {
                  value: "Warehouse ",
                  label: "Warehouse ",
                },
                {
                  value: "Customer ",
                  label: "Customer ",
                },
                {
                  value: "Logistics ",
                  label: "Logistics ",
                },
                {
                  value: "Sales ",
                  label: "Sales ",
                },
                {
                  value: "Finance ",
                  label: "Finance ",
                },

              ]}
              formik={formik}
              name="division"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            /> */}
              <NuInput
                label="Attn."
                type="text"
                formik={formik}
                placeholder="Attn."
                name="attn"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
            <NuDate
              label="Dead Line"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deadLine"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            
            <FormikSelectTwo
              type="text"
              label="Sales Person"
              placeholder="Choose"
              Options={Salesman}
              formik={formik}
              name="salesPerson"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              FixPosition={false}
              PortalTarget={false}
            />
            <DropDownAddMetaData
              label="Status"
              placeholder="Choose"
              Options={status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refStatus}
              displayProperty={"Status"}
              propertyName={"rfqStatus_pr"}
              FixPosition={false}
              PortalTarget={false}
            />
            <NuTextArea
              label="Notes From Customer"
              type="text"
              formik={formik}
              placeholder="Notes From Customer"
              name="notesFromCustomer"
              width="w-full md:w-1/2"
              new_width="w-full md:w-1/2 lg:w-2/3"

            />
            {/* <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2"
            /> */}
            <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
    YPR Attachement :
  </p></div> 
            <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
              {data?.attachments &&
                  filePathList?.map((imgs, idx) => {
                    console.log("img", imgs);
                    let Ext = imgs?.file_name.split(".").reverse()[0];
                    return (
                      <div
                        className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          <CloseIcon color="white" height="8" width="8" />
                        </div>
                        <div
                          className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          
                          <div className=" w-8 h-8">
                  {videotype.includes(Ext) ? <Video /> : null}

                  {Ext === ("docx" || "doc"||"txt") ? (
                    <Doc />
                  ) : Ext === ("xlsx" || "xls") ? (
                    <Xls />
                  ) : Ext === "txt" ? (
                    <Txt />
                  ) : Ext === "pdf" ? (
                    <Pdf />
                  ) : Ext === "pptx" ? (
                    <>No file</>
                  ) : 
                  <img src={imgs?.file} alt="#" width="120%" />

                  }
                  </div>
                  <p className=" w-[50px] text-[11px] truncate">{imgs?.file_name}</p>
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                />
              </div>
            </div>
            </div>
          </div>
         
          
         
           <div className="w-full mx-auto h-auto bg-white rounded-md overflow-hidden mt-4 ">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto mb-4">
       
          <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  Requested Items :
  </p></div>  
            <div className="w-full px-2 m-2 flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-start gap-3 border border-solid rounded-lg">
            <SelectProductWithError
                   type="text"
                   label="Product "
                   placeholder="Product "
                   Options={filterdItem}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                   ErrMessage={productError}
                   px='0'
                   handleClick={()=>{}}
                   hasAddButton={false}
                   setSearch={setSearch}
                   FixPosition={false}
                   PortalTarget={false}
                 />
                 <DropDownAddUnitWithError
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="product_unit"
                width="w-full md:w-1/2 lg:w-1/4"
                // getFunc={refUnit}
                displayProperty={"Add Unit"}
                propertyName={"Unit_item"}
                ErrMessage={unitError}
                hasAddButton={false}
                FixPosition={false}
                PortalTarget={false}
              />
         <NuInputWithError
           label="Quantity"
           type="number"
           formik={formik}
           placeholder="Quantity"
           name="quantity"
           width="w-full md:w-1/2 lg:w-1/4 "
           ErrMessage={quantityError}
         />

<button
                onClick={handleAdd}
                  type="button"
                 className=" p-1 mt-10 m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
                           </div>
            <div className="w-full bg-white overflow-hidden mt-5">
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className="w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.No </p>

                <p className=" w-[55%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className=" w-[15%] text-center pl-4 text-white text-xs font-medium flex justify-center">
                  Unit
                  <span className='pl-1 cursor-pointer' onClick={()=>{setIsEditUnit(prv => !prv)}}>
                    {isEditUnit === true && <EditIcon width={"14"} height={"14"} color='yellow' /> }
                    {isEditUnit === false && <EditIcon width={"14"} height={"14"} /> }
                 </span>
                </p>
                <p className=" w-[20%] text-center text-white text-xs font-medium flex justify-center">
                  Quantity
                  <span className='pl-1 cursor-pointer' onClick={()=>{setIsEditQua(prv => !prv)}}>
                     {isEditQua === true && <EditIcon width={"14"} height={"14"} color='yellow' /> }
                     {isEditQua === false && <EditIcon width={"14"} height={"14"} /> }
                  </span>
                </p>

                <p className=" w-[5%] text-right text-white text-xs font-medium">
                  Action
                </p>
              </div>
              {(items?.length>0 || dataa.productList?.length>0)?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {items.map((List, index) => {
                return (
                  <div
                    key={index}
                    className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100"
                  >
                    <p className="w-[5%]  text-left   text-xs font-light py-2.5">
                                    {index + 1}
                    </p>
                    <p className=" w-[55%] text-left  text-xs font-light py-2.5">
                      {List?.productName.label}
                    </p>
                    <p className=" w-[15%] text-center pl-4  text-xs font-light">
                        <Select
                          value={Unit.find(item=> item?.value == List?.product_unit)||List?.product_unit}
                          options={isEditUnit ? Unit : []}
                          className="w-full"
                          onChange={(value) =>
                            handleProductUnitChange(value, index)
                          }
                          isDisabled={!isEditUnit}
                        />
                    </p>

                    <p className=" w-[20%] text-center  text-xs font-light py-2.5">
                    <input
                     type='number'
                     className='w-11/12 mx-auto outline-none text-center'
                     value={List?.quantity || ""}
                     disabled={!isEditQua}
                     onChange={(e) => handleQuantityChange(e, index)}
                    />
                    </p>

                    <div className="w-[5%] text-right py-2.5">
                      <button
                        onClick={() => handleDeleteItem(index)}
                        type="button"
                        className="text-red-500 cursor-pointer  ml-4 border-none"
                      >
                        <Delete color="red" height={4} />
                      </button>
                    </div>
                  </div>
                );
              })}
              {dataa?.productList?.map((List, index) => {
                return (
                  <div
                    key={index}
                    className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100"
                  >
                     <p className="w-[5%]  text-left   text-xs font-light py-2.5">
                                    {index + 1 + items.length}
                    </p>
                    <p className=" w-[55%] text-left  text-xs  font-light py-2.5">
                      {List?.product_name}
                    </p>
                    <p className=" w-[15%] text-center pl-4  text-xs font-light">
                      <Select
                          value={Unit.find(item=> item?.value == List?.product_unit)||List?.product_unit}
                          options={isEditUnit ? Unit : []}
                          className="w-full"
                          onChange={(value) =>
                            handleProductUnitChangeAlredyExit(value, index)
                          }
                          isDisabled={!isEditUnit}
                        />
                    </p>
                    <p className=" w-[20%] text-center  text-xs font-light py-2.5">
                    <input
                     type='number'
                     className='w-11/12 mx-auto outline-none text-center'
                     value={List?.quantity}
                     disabled={!isEditQua}
                     onChange={(e) => handleQuantityChangeAlredyExit(e, index)}
                    />
                    </p>

                    <div className="w-[5%] text-right py-2.5">
                      <button
                        onClick={() => handleDelete(List?.productId)}
                        type="button"
                        className="text-red-500 cursor-pointer  ml-4 border-none"
                      >
                        <Delete color="red" height={4} />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

              {/* </div> */}
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 py-4">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel();
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
    </div>
  );
}

export default EditPr;